const Libertarians = [
  {
    link: "https://www.youtube.com/user/DanielFragaBR",
    image: "libertarians/danielfraga.jpg",
    name: "Daniel Fraga",
    filter: ["filosofia", "economia", "politica", "bitcoin"],
  },
  {
    link: "https://www.youtube.com/channel/UCEoSANmneNddweVvUghnfpQ",
    image: "libertarians/bitconheiros.jpg",
    name: "Bitcoinheiros",
    filter: ["bitcoin", "podcast", "tecnologia"],
  },
  {
    link: "https://www.youtube.com/channel/UCLTWPE7XrHEe8m_xAmNbQ-Q",
    image: "libertarians/visaolibertaria.jpg",
    name: "Visão Libertária",
    filter: ["noticias", "politica"],
  },
  {
    link: "https://www.youtube.com/channel/UCSyG9ph5BJSmPRyzc_eGC4g",
    image: "libertarians/ancapsu.jpg",
    name: "Ancap.SU",
    filter: ["filosofia", "economia", "noticias", "portal"],
  },
  {
    link: "https://www.youtube.com/user/paulokogos",
    image: "libertarians/kogos.jpg",
    name: "Paulo Kogos",
    filter: ["filosofia", "economia", "politica"],
  },
  {
    link: "https://www.youtube.com/channel/UC4H35F5f2c5cyR-pbZlt12Q",
    image: "libertarians/tiagosalem.jpg",
    name: "Tiago Salem",
    filter: ["tecnologia", "bitcoin"],
  },
  {
    link: "https://www.youtube.com/channel/UCSv_erzIMsN8-hKrCfggxxw",
    image: "libertarians/na-toca-do-coelho.jpg",
    name: "Na Toca do Coelho",
    filter: ["bitcoin", "tecnologia", "economia"],
  },
  {
    link: "https://www.youtube.com/c/Sobrevivencialismo",
    image: "libertarians/sobrevivencialismo.jpg",
    name: "Sobrevivencialismo",
    filter: ["sobrevivência"],
  },
  {
    link: "https://www.youtube.com/channel/UC25qcfectEPNQQS_BpyA1aA",
    image: "libertarians/tapadamaoinvisivel.jpg",
    name: "Tapa da Mão Invisível",
    filter: ["filosofia", "politica", "podcast"],
  },
  {
    link: "https://www.youtube.com/c/MarcoBatalha",
    image: "libertarians/marco-batalha.jpg",
    name: "Marco Batalha",
    filter: ["filosofia", "politica", "biologia", "noticias", "bitcoin"],
  },
  {
    link: "https://www.youtube.com/channel/UC98rzzueHX1251cyGEbmqtg",
    image: "libertarians/etoempire.jpg",
    name: "Etoempire",
    filter: ["filosofia", "economia", "politica"],
  },
  {
    link: "https://www.youtube.com/channel/UCLJkh3QjHsLtK0LZFd28oGg",
    image: "libertarians/fernandoulrich.jpg",
    name: "Fernando Ulrich",
    filter: ["economia", "bitcoin"],
  },
  {
    link: "https://www.youtube.com/channel/UCRzbB2cCyVqJ6y_Ybg_vdaA",
    image: "libertarians/proofoftalk.jpg",
    name: "Proof of Talk",
    filter: ["bitcoin", "podcast", "tecnologia"],
  },
  {
    link: "https://www.youtube.com/channel/UCHiPlljsU3rFJvNegG6BEPw",
    image: "libertarians/sokath.jpg",
    name: "Sokath",
    filter: ["filosofia"],
  },
  {
    link: "https://www.youtube.com/channel/UC6wxyZmdzqO8HTI_TY7GBmQ",
    image: "libertarians/scheffel.jpg",
    name: "Scheffel",
    filter: ["filosofia"],
  },
  {
    link: "https://www.youtube.com/channel/UCXwOMihopuhWrksS6t4IAYg",
    image: "libertarians/gabr.jpg",
    name: "gabr",
    filter: ["filosofia"],
  },
  {
    link: "https://www.youtube.com/c/KoreacomK",
    image: "libertarians/koreacomk.jpg",
    name: "KoreacomK",
    filter: ["bitcoin", "economia"],
  },
  {
    link: "https://www.youtube.com/c/21Milh%C3%B5esPodcast",
    image: "libertarians/21milhoes.jpg",
    name: "21 Milhões Podcast",
    filter: ["podcast", "bitcoin", "economia"],
  },
  {
    link: "https://www.youtube.com/c/LdeLiberdade",
    image: "libertarians/l-de-liberdade.jpg",
    name: "L de Liberdade",
    filter: ["filosofia", "politica", "noticias"],
  },
  {
    link: "https://www.youtube.com/channel/UCrVHeThIK0-_3x2me08ygTg",
    image: "libertarians/explica-bitcoin.jpg",
    name: "Explica Bitcoin",
    filter: ["bitcoin", "economia", "podcast"],
  },
  {
    link: "https://www.youtube.com/channel/UCxrhyTbGqXWy5zr8HAeDPuQ",
    image: "libertarians/hans-investe.jpg",
    name: "Hans Investe",
    filter: ["bitcoin", "economia", "podcast"],
  },
  {
    link: "https://www.youtube.com/channel/UCcgAa7wJ1OwRzd-TGFwIxPA",
    image: "libertarians/fhoer.jpg",
    name: "Fhoer",
    filter: ["filosofia"],
  },
  {
    link: "https://www.youtube.com/channel/UC4z5OsOcltedh3iZKYJ2UcA",
    image: "libertarians/pessimista.jpg",
    name: "O Pessimista",
    filter: ["filosofia"],
  },
  {
    link: "https://www.youtube.com/user/adrianogianturco",
    image: "libertarians/gianturco.jpg",
    name: "Adriano Gianturco",
    filter: ["filosofia", "direito", "politica", "economia"],
  },
  {
    link: "https://www.youtube.com/channel/UCIU6wWUaWx2ilIiAjeIc6FA",
    image: "libertarians/culturalibertaria.jpg",
    name: "Cultura Libertária",
    filter: ["filosofia", "entrevistas", "podcast"],
  },
  {
    link: "https://www.youtube.com/c/brunogarcia99",
    image: "libertarians/brunogarcia.jpg",
    name: "Bruno Garcia",
    filter: ["bitcoin", "tecnologia"],
  },
  {
    link: "https://www.youtube.com/channel/UCOq_USHG_AogX4dwqBYlvhQ",
    image: "libertarians/sotospeak.jpg",
    name: "So to Speak",
    filter: ["filosofia", "economia"],
  },
  {
    link: "https://www.youtube.com/channel/UCtvt8kfIvCH7yT0b5MFTVNA",
    image: "libertarians/recapitulacao.jpg",
    name: "Recapitulação Ancap",
    filter: ["filosofia", "politica", "economia"],
  },
  {
    link: "https://www.youtube.com/channel/UCRJooxeIg3O29IDIcPZPaCg",
    image: "libertarians/direito.jpg",
    name: "Direito Sem Juridiquês",
    filter: ["filosofia", "direito"],
  },
  {
    link: "https://www.youtube.com/channel/UCYyu1QvD3Y7pvtnNLxCB7Gw",
    image: "libertarians/lobo.jpg",
    name: "Lobo Conservador",
    filter: ["politica", "economia", "filosofia"],
  },
  {
    link: "https://www.youtube.com/channel/UCY0v0I-00pjwdN5bzOtZLkA",
    image: "libertarians/oagorista.jpg",
    name: "O Agorista",
    filter: ["filosofia", "economia"],
  },
  {
    link: "https://www.youtube.com/user/alcancetime",
    image: "libertarians/tayrone.jpg",
    name: "Tayrone",
    filter: ["bitcoin", "tecnologia"],
  },
  {
    link: "https://www.youtube.com/channel/UCsFgHFNWP-1I9mCoA59TixA",
    image: "libertarians/midiaancap.jpg",
    name: "Mídia BH",
    filter: ["filosofia", "politica", "economia"],
  },
  {
    link: "https://www.youtube.com/channel/UCnL00qrAdEH4yosYTbgNVlw",
    image: "libertarians/stonegarou.jpg",
    name: "Stone Garou",
    filter: ["filosofia", "politica", "economia"],
  },
  {
    link: "https://www.youtube.com/channel/UCvHBUq2A27OeITnWesAwUJw",
    image: "libertarians/jayjay.jpg",
    name: "Jay Jay Ⓥ",
    filter: ["música"],
  },
  {
    link: "https://www.youtube.com/channel/UC5rRQGCPscqQY0tdfO_JCxg",
    image: "libertarians/augustus.jpg",
    name: "sePODEtemCAST",
    filter: ["humor", "podcast"],
  },
  {
    link: "https://www.youtube.com/user/misesbrasil",
    image: "libertarians/institutorothbard.jpg",
    name: "Instituto Rothbard",
    filter: ["filosofia", "portal"],
  },
  {
    link: "https://www.youtube.com/channel/UCb9T91q727Ld4c3lqq3w6Xw",
    image: "libertarians/institutomisesbrasil.jpg",
    name: "Instituto Mises Brasil",
    filter: ["filosofia", "portal"],
  },
  {
    link: "https://www.youtube.com/channel/UCD65KdRPT7v065JR62rR_qQ",
    image: "libertarians/universidadelibertaria.jpg",
    name: "Universidade Libertária",
    filter: ["filosofia", "noticias", "entrevistas"],
  },
  {
    link: "https://www.youtube.com/channel/UCrHYElSw0UhBuVsFsiuXzgQ",
    image: "libertarians/categoricamente.jpg",
    name: "Categoricamente",
    filter: ["politica", "noticias"],
  },
  {
    link: "https://www.youtube.com/channel/UCzSM3O0WQJVFpiRVwFWdDWg",
    image: "libertarians/jack.jpg",
    name: "Jack",
    filter: ["filosofia"],
  },
  {
    link: "https://www.youtube.com/channel/UCmhpqvtjSv3dYqBM-IfVxgw",
    image: "libertarians/cafelibertario.jpg",
    name: "Café Libertário",
    filter: ["filosofia", "podcast"],
  },
  {
    link: "https://www.youtube.com/channel/UCL44189IdD5L_GhdPPq7bCQ",
    image: "libertarians/liberdadecultural.jpg",
    name: "Liberdade Cultural",
    filter: ["humor"],
  },
  {
    link: "https://www.youtube.com/channel/UCjztKv3bXFXJ8Toc3AwJ89w",
    image: "libertarians/mairabasilio.jpg",
    name: "Maira Basílio",
    filter: ["música"],
  },
  {
    link: "https://www.youtube.com/user/Guerrelhominas",
    image: "libertarians/costa.jpg",
    name: "Costa, o libertário",
    filter: ["filosofia"],
  },
  {
    link: "https://www.youtube.com/channel/UCcFnjgoIMcUhyPz505RsAcA",
    image: "libertarians/ojeda.jpg",
    name: "Felipe Ojeda",
    filter: ["filosofia", "noticias", "politica"],
  },
];

export default Libertarians;
